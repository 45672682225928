import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/caregiving.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Caregiving Services | Camelo"
        metaDescription="Intuitive scheduling & time tracking software for caregiving services. Provide care instantly with the help of Camelo today!"
        header="Provide care instantly with our intuitive scheduling app."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
